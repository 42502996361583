/**
 * Testing Components out here so that I don't clutter up other pages anymore
 */

import { Component } from 'react';
import Search from './search.jsx';

export default class ComponentTestingPage extends Component {
    render(){
        return(
            // Call Components Here
            <>
                
                <Search/>

            </>
        );
    }
}